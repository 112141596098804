import { useEffect, useState } from 'react';
import Table from 'components/generics/Table/ReactTable';
import { FLIGHTIMES_STATS_HEADERS } from 'utils/const';
import { formatMinutesToHour, prepareTable } from 'utils/func';
import './FlightTimesStats.style.scss';
import InputLabel from 'components/generics/InputLabel/InputLabel';
import Input from 'components/generics/Input/Input';
import { getFlightTimes } from 'services/API/Stats';
import Bar from '../../../generics/Charts/Bar';
import { Loader } from '../../../generics/Loader/Loader';

const FlightTimesStats = () => {
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [arrayData, setArrayData] = useState([]);
  const [filter, setFilter] = useState('');
  const [dispatchFilter, setDispachFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFlightTimes()
      .then(res => {
        setData({
          datas: prepareTable(FLIGHTIMES_STATS_HEADERS, res.data.aircrafts),
          headers: FLIGHTIMES_STATS_HEADERS,
        });
        setLoading(false);
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (data) {
      const filteredData = data.datas.filter(item => item.reg.toLowerCase().includes(filter.toLowerCase()));
      setFilteredData(filteredData);
      setArrayData(
        filteredData.map(item => ({
          ...item,
          totalMinutesFlightTimeUtc: formatMinutesToHour(item.totalMinutesFlightTimeUtc),
          totalMinutesFlightTimeTlb: formatMinutesToHour(item.totalMinutesFlightTimeTlb),
          contractPlannedHours: item.contractPlannedHours,
          remainingMinutes: formatMinutesToHour(item.remainingMinutes),
        })),
      );
    }
  }, [filter, data]);

  const formatChartData = data => {
    return data.map(v => ({
      ...v,
      totalMinutesFlightTimeUtc: Math.round(+v.totalMinutesFlightTimeUtc/60),
      totalMinutesFlightTimeTlb: Math.round(+v.totalMinutesFlightTimeTlb/60),
      remainingMinutes: Math.round(+v.remainingMinutes/60),
    }));
  }

  return (
    <div id="FlightTimesStats">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="statsContainer">
            <div id="table">
              <InputLabel label="Reg filter" name="reg">
                <Input type="text" name="reg" placeholder="Enter a registration" defaultStyle="primary" onChange={setFilter} />
              </InputLabel>
              <Table selectedRows={() => {}} datas={arrayData} headers={data.headers} filters={false} selectable={false} name="flightTimesFilters" />
            </div>
            <div className="statsSection" id="chart">
              <h3>Aircraft hours</h3>
              <div>
                <label htmlFor="switchData">
                  <input
                    name="switchData"
                    type="checkbox"
                    onChange={() => {
                      setDispachFilter(!dispatchFilter);
                    }}
                    checked={dispatchFilter}
                  />{' '}
                  Use table filter
                </label>
              </div>
              <div className="graphContainer">
                <Bar
                  data={dispatchFilter ? formatChartData(filteredData) : formatChartData(data.datas)}
                  keys={['totalMinutesFlightTimeUtc', 'totalMinutesFlightTimeTlb', 'contractPlannedHours', 'remainingMinutes']}
                  legends={['Planned hours', 'Tlb hours', 'Contracted hours', 'Remaining hours']}
                  labels={['Planned hours', 'Tlb hours', 'Contracted hours', 'Remaining hours']}
                  mode={'grouped'}
                  indexBy={'reg'}
                  colors={['#03A9F4', '#016593', '#4ec6fd', '#029ce3', '#01577e']}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FlightTimesStats;
